import { auth, loginLog, getTerrId, getUserRole, getUserTa } from '@/api/user';
import * as py from '@/api/Physicians';
import * as help from '@/api/help';
import { setToken } from '@/utils/auth';
import { setLocalStorage, getLocalStorage, removeAllLocalStorage } from '@/utils/index';
import * as crypto from 'crypto-js';
import * as moment from 'moment';

export default {
  data() {
    return {
      isDirectLogin: false,
      isloging: false,
      clearable: true,
      type: 'text',
      value: '',
      showEye: true,
      loginObj: {
        userId: '',
        userName: '',
        userNameTips: '',
        userIdTips: ''
      },
      eyeObj: {
        open: false,
        reverse: false
      },
      loading: false,
      login_client_type: ''
    };
  },
  computed: {
  },
  created() {
    const USERID = getLocalStorage('user_id');
    if (USERID) {
      this.loginObj.userId = USERID;
    }
    removeAllLocalStorage();
  },
  mounted() {
    this.$i18n.locale = 'ZH';
    setLocalStorage('language', this.$i18n.locale);
    if (this.$route.query.user_id) {
      this.loginObj.userId = this.$route.query.user_id;
      this.isDirectLogin = true;
      this.loginFn('userid');
    }
  },
  methods: {
    settingLan(LAN) {
      this.$i18n.locale = LAN;
      setLocalStorage('language', this.$i18n.locale);
    },
    blurUserName() {
      if (this.loginObj.userName) {
        this.loginObj.userNameTips = '';
      }
    },
    blurPwd() {
      if (this.loginObj.userId) {
        this.loginObj.userIdTips = '';
      }
    },
    addLoginLog(userRole) {
      let sessionId = 's' + (new Date().getTime());
      let data = {
        user_id: userRole.user_id,
        login_time: new Date(),
        session: sessionId,
        login_type: 'mobile',
        login_client_type: this.login_client_type,
        territory_id: userRole.territory_id
      };
      if (!data.territory_id) {
        delete data.territory_id;
      }
      setLocalStorage('sessionId', sessionId);
      loginLog(data).then(res => {
      });
    },
    ajaxLogin() {
      let data = {
        user_name: this.loginObj.userName,
        user_id: this.loginObj.userId
      };
      setLocalStorage('user_id', this.loginObj.userId);
      setLocalStorage('user_name', this.loginObj.userName);
      let loginTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss a');
      setLocalStorage('login_time', loginTime);
      this.isloging = true;
      auth(data.user_id).then(token => {
        setToken({
          'seed': token.seed,
          'app_id': token.token,
          'device_id': crypto.TripleDES.encrypt(token.login_id, crypto.enc.Utf8.parse(token.seed.substr(0, 24)), { mode: crypto.mode.ECB }).toString()
        });
        this.$store.dispatch('setSeed', token.seed);
        this.authLogin(data);
      }).catch(e => {
        this.authLogin(data);
      });
    },
    getSortDy(user_id) {
      py.getSortDynamicsByUserId(user_id).then(res => {
        if (res && res.value.length) {
          setLocalStorage('sort_dynamic_id', res.value[0].sort_dynamic_id);
          setLocalStorage('SortDynamics', JSON.stringify(res.value[0]));
        }
      }).catch(e => {
      });
    },
    authLogin(data) {
      // login(data).then(res => {
      getTerrId(data).then(res => {
        this.addLoginLog();
        if (res.value && res.value.length > 0) {
          // setLocalStorage('user_name', res.value[0].territory_name);
          setLocalStorage('territory_id', res.value[0].territory_id);
          setLocalStorage('territory_type', res.value[0].territory_type);
          // 编辑权限
          if (res.value[0].user) {
            this.$store.dispatch('setEDITPERSSION', res.value[0].user.user_edit_permission);
          }
          this.$store.commit('GET_TERR_TYPE');
          if (res.value[0].territory_type === 'MR') {
            const stepObj = getLocalStorage('stepObj');
            // no 不是首次 yes 首次
            if (!stepObj) {
              let stepObj = {};
              for (let i = 0; i < 9; i++) {
                stepObj['step' + i] = 0;
              }
              setLocalStorage('stepObj', JSON.stringify(stepObj));
              this.$store.dispatch('setFIRST', 'yes');
              this.$store.dispatch('setSTEP', 0);
            }
          } else {
            this.$store.dispatch('setSTEP', -1);
          }
          this.getSortDy(res.value[0].user_id);
          this.$store.dispatch('settingUserData', res.value[0]);
          this.$store.dispatch('setWaterMarket');
          this.isloging = false;
          this.loading = false;
          this.$router.push({
            path: '/hcp/home'
          });
        } else {
          this.loginObj.userIdTips = '该区域暂无数据';
          this.loginObj.userNameTips = '该区域暂无数据';
          this.isloging = false;
          this.loading = false;
        }
      }).catch(e => {
        this.loginObj.userIdTips = '';
        this.loginObj.userNameTips = '';
        this.isloging = false;
        this.loading = false;
      });
    },
    loginFn(type) {
      if (this.isloging) {
        return;
      }
      this.login_client_type = type;
      this.isloging = true;
      let data = {
        // user_name: this.loginObj.userName,
        user_id: this.loginObj.userId
      };

      // setLocalStorage('user_id', data.user_id);
      // this.$router.push({
      //   path: '/hcp/list'
      // });

      getUserRole(data).then(res => {
        if (res.value && res.value.length > 0) {
          this.addLoginLog(res.value[0]);
          setLocalStorage('is_sales', res.value[0].is_sales);
          setLocalStorage('is_msl', res.value[0].is_msl);
          setLocalStorage('user_id', res.value[0].user_id);
          setLocalStorage('territory_type', res.value[0].territory.territory_type);
          setLocalStorage("territory_id", res.value[0].territory.territory_id);
          // setLocalStorage("user_name", res.value[0].territory.user_name);
          setLocalStorage("user_name", res.value[0].user.user_name);

          this.$store.dispatch('setWaterMarket');

          // For MSL, check TA
          getUserTa(data).then(resTa => {
            if (resTa.value && resTa.value.length > 0) {
              setLocalStorage('ta', resTa.value[0].ta);
              setLocalStorage('ta_id', resTa.value[0].ta_id);
              setLocalStorage('user_id', resTa.value[0].user_id);
              setLocalStorage('tas', resTa.value.map(item => item.ta_id).join(','));
              resTa.value.forEach((item) => {
                if (item.ta_id === 'Neurology_000001') {
                  setLocalStorage('is_neu', 1);
                } else if (item.ta_id === 'Psychiatry_000001') {
                  setLocalStorage('is_psy', 1);
                }
              });
            }
            this.getUserIdKey(res.value[0].user_id);
            this.isloging = false;
          });
        }
        this.isloging = false;
      });
    },
    getUserIdKey(userId) {
      help.encryption(userId).then(res => {
        if (res) {
          setLocalStorage('user_id_key', res);
          const isMsl = getLocalStorage('is_msl') === '1';
          if (isMsl) {
            console.log('isMsl', isMsl);
            document.title = 'KOL 360';
            this.$router.push({
              path: "/kol/home",
              query: {
                user_id: res,
                routeTxt: 'home'
              },
            });
          } else {
            this.$router.push({
              path: "/hcp/home",
              query: {
                user_id: res,
                routeTxt: 'home'
              },
            });
          }
        }
      });
    },
  }
};
